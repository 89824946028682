.project-info {
  text-align: center;
  color: white;
  position: absolute;
  top: 27%;
  left:27%;
  /* margin: 0 auto; */
  visibility: hidden;
  max-width: 50%; 
}

.project:hover .project-info {
  opacity: 1;
  transform: scale(1.1); 
  visibility: visible;
}

.project-img {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 0.5rem;
  height: 15rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.project:hover .project-img {
  opacity: 0.1;
}

.link-title {
  margin-bottom: 30px;
  top: 10%;
}

.link-left {
  float: left;
  font-size: 30px;
}

.link {
  font-size: 30px;
}

.link:hover, 
.link-left:hover {
  text-decoration: none;
  color: white;
}

.link-right {
  float: right;
}

.link:hover,
.link-left:hover,
.link-right:hover {
  border: 2px white solid;
  padding: 5px;
}

.vertical {
  border-left: 2px solid white;
  height: 50px;
  position: absolute;
  left: 50%;
}

@media screen and (max-width: 768px) {
  .projects-center {
    display: grid;
    grid-template-rows: 1fr;
    -webkit-row-gap: 2rem;
    -moz-row-gap: 2rem;
    row-gap: 1rem;
  }

  .project-info {
    top: 0;
    margin: 10% auto;
  }

  .link-title,
  .link-left,
  .link-right {
    font-size: 20px;
  }

  .link-title {
    margin-bottom: 10px;
  }

  a.link-right {
    height: 25%;
  }
  
  .vertical {
    height: 50px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .projects-center {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}

@media screen and (min-width: 1025px) {
  .projects-center {
    display: grid;
    grid-template-rows: 300px 300px;
    gap: 4rem;
    grid-template-areas:
      "a b"
      "c d";
  }
  .project-1 {
    grid-area: a;
  }
  .project-2 {
    grid-area: b;
  }
  .project-3 {
    grid-area: c;
  }
  .project-4 {
    grid-area: d;
  }
  
}