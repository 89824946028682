[data-title]:hover:after {
  opacity: 1;
  transition: all 0.2s ease 0.6s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #FCEBF6;    
  color: #23395D;
  bottom: 0;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1); 
  position: absolute;
  padding: 4px 8px 4px 8px;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .typewriter {
    margin: 0px;
  }
}
