a {
  text-decoration: none;
}

.window {
  max-width: 1100px;
  background: #FCEBF6;
    /* for macbook size */
  height: 72vh;
    /* for macbook size */
  width: 60vw;
  border-radius: 15px;
  margin: 0 auto;
  /* for macbook size */
  font-size: 1rem;
}

.bar {
  height: 35px;
  border-radius: 15px 15px 0 0;
  background: #E1DFE1;
  /* justify-content: center; */
}

.bar > h3 {
  font-size: 20px;

}

.buttons {
  margin-left: 10px;
  /* padding-left: 10px; */
  padding-top: 3px;
}

.buttons:hover a {
  visibility: visible;
}

.close, 
.minimalize,
.zoom {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 13px;
  float: left;
}


.close {
  background: #ef233c;
  border: 1px solid #ef233c;
}


.close:active {
  background: #c14645;
  border: 1px solid #b03537;
}

.minimalize {
  background: #FFBD44;
  border: 1px solid #FFBD44;
}

.minimize:active {
  background: #c08e38;
  border: 1px solid #af7c33;
}


.zoom {
  background: #00CA4E;
}

.zoom:active {
  background: #029740;
  border: 1px solid #128435;
}

.content img {
  height: 325px;
  /* for macbook size */
  margin: 5vh;
}

.about-content {
  /* padding-left: -50px; */
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 15px;
  padding-left: 60px;
}

/* Mobile Phone + Small Screen */
@media screen and (max-width: 768px) {
  .content {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    /* display: flex; */
  }
  .content p {
    font-size: 13px;
  }

  .about-content {
    padding: 7px;
  }

  .image {
    top: 0;
    margin: 0 auto;
  }
  .content img {
    height: 25%;
    width: 15%;
    margin-top: 10px;
    margin-bottom: 10px;
    /* margin: 10px auto; */
    border-radius: 75%;
  }
  .bar {
    display: none;
  }
  .window {
    width: 90%;
    height: 100%;
  }
}

/* Tablets */
@media screen and (max-width: 1024px) and (min-width: 769px) { 
  .window {
    width: 100%;
    height: 75%;
  }
  
  .image {
    top: 2.5rem;
    left: 2.5rem;
    margin: 0;
  }

  .content img {
    margin: 0;
  }

  .content p {
    margin: 15px;
  }
}

@media screen and (min-width: 1900px) {
  .window {
    max-width: 1100px;
    background: #FCEBF6;
    height: 64vh;
  }

  .about-content {
    /* padding-left: -50px; */
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 17px;
    padding-left: 60px;
  }
}

